/*global google*/
import { AutomatitCarousel } from './automatit_carousel.m';
import {getMapsHelper} from './util/mapsHelper.js';

const facCaro = {
	caro: document.querySelector('#facility_caro'),
	init() {
		AutomatitCarousel({
			element: this.caro,
			showDots: true,
			useChevrons: false,
			imagesAsBackgrounds: true,
			images: window.facCaroImgs
		});
	}
};

const facToggles = {
	container: document.querySelector('#facility_caro'),
	toggles: document.querySelectorAll('.toggle_button'),
	map: document.querySelector('#facility_map'),
	mobile: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_info_wrapper'),
	mobileClick() {
		this.mobile.toggleAttribute('open');
		this.info.toggleAttribute('open');
	},
	toggleClick(e) {
		const clickedToggle = e.target.closest('.toggle_button');

		if(clickedToggle) {
			this.toggles.forEach(toggle => toggle.removeAttribute('active'));
			clickedToggle.setAttribute('active', '');

			if(clickedToggle.id === "toggle_fac_map") {
				this.map.setAttribute('active', '');
			} else {
				this.map.removeAttribute('active');
			}
		}
	},
	init() {
		this.container.addEventListener('click', e => this.toggleClick(e));
		this.mobile.addEventListener('click', () => this.mobileClick());
	}
};

const facilityFilters = {
	filters: document.querySelectorAll('#facility_filters button'),
	rows: document.querySelectorAll('.rates_row'),
	noUnits: document.querySelector('#no_units'),
	filterClick(e) {
		const clickedButton = e.target.closest('button');
		if (clickedButton) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedButton.setAttribute('active', '');

			let numUnits = 0;

			this.rows.forEach(row => {
				row.setAttribute('hide', '');
				if (clickedButton.getAttribute('size') === row.getAttribute('size') || clickedButton.getAttribute('size') === 'all') {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				this.noUnits.style.display = 'none';
			} else {
				this.noUnits.style.display = 'block';
			}
		}
	},
	init() {
		document.querySelector('#facility_filters').addEventListener('click', e => this.filterClick(e));
	}
};




const facMap = {
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();
		const theMap = mapsHelper.createMap({
			useRichmarker: true,
			element: document.querySelector('#facility_map'),
			locationElementSelector: '#facility_map',
			markerReducer: (el) => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/ic_mappin.svg" alt=""/></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(theMap, 'idle', () => {
			theMap.setZoom(15);
		});
	}
};

export const initFacMap = () => facMap.init();
export const initFacCaro = () => facCaro.init();
export const initFacToggles = () => facToggles.init();
export const initFacilityFilters = () => facilityFilters.init();