const imgSlideover = {
	rowWrap: document.querySelector('#home_hero_locations'),
	images: document.querySelectorAll('.img_slideover'),
	rowMouseIn(e) {
		const row = e.target.closest('.inc_fac_row');

		if(row) {
			this.images.forEach(img => img.removeAttribute('show'));
			const activeImg = document.querySelector(`.slideover_img[link="${row.getAttribute('link')}"]`);
			if(activeImg) {
				activeImg.setAttribute('show', '');
			}
		}
	},
	rowMouseOut(e) {
		const row = e.target.closest('.inc_fac_row');
		if(row) {
			const activeImg = document.querySelector(`.slideover_img[link="${row.getAttribute('link')}"]`);
			if(activeImg) {
				activeImg.removeAttribute('show');
			}
		}
	},
	init() {
		this.rowWrap.addEventListener('mouseover', e => this.rowMouseIn(e));
		this.rowWrap.addEventListener('mouseout', e => this.rowMouseOut(e));
	}
};

export const initImgSlideover = () => imgSlideover.init();