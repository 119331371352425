import { contactSubmit } from './contact.js';
import { initMobileNav } from './header.js';
import { initImgSlideover } from './home.js';
import { initmultiMap } from './multiMap.js';
import { tabButtons } from './faqs.js';
import { initTipsList } from './tips.js';
import { initFacCaro, initFacToggles, initFacMap, initFacilityFilters } from './facility';
import { initReviewsCaro } from './reviewsCaro';

initMobileNav();

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#home_hero')) {
	initImgSlideover();
}

if(document.querySelector('#facility_caro')) {
	initFacCaro();
	initFacToggles();
}

if(document.querySelector('#facility_map')) {
	initFacMap();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.querySelector('#inc_fac_map')) {
	initmultiMap();
}

if (document.getElementById('tab_title_container')){
	tabButtons();
}

if(document.querySelector('.tips_list')) {
	initTipsList();
}
