const hamburger = {
	toggleBtn: document.querySelector('#hamburger'),
	navEl: document.querySelector('#nav'),
	btnClick() {
		this.toggleBtn.toggleAttribute('active');
		this.navEl.toggleAttribute('active');
	},
	init() {
		this.toggleBtn.addEventListener('click', () => this.btnClick());
	}

};

export const initMobileNav = () => hamburger.init();