import {getMapsHelper} from './util/mapsHelper.js';

const multiMap = {
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();
		mapsHelper.createMap({
			useRichmarker: true,
			element: document.querySelector('#inc_fac_map'),
			locationElementSelector: '.inc_fac_row',
			markerReducer: (el) => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a class="map_pin" href="/self-storage/${el.getAttribute('link')}">
						<span>${el.getAttribute('count')}</span>
						<img src="/dist/images/icons/ic_mappin.svg" alt=""/>
					</a>`
				};
			}
		});
	}
};

export const initmultiMap = () => multiMap.init();