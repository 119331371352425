const tabSystem = (function(){
	let my = {},
		tabs = document.querySelectorAll('.tab_title');

	function _assignListeners(){
		[].forEach.call(tabs, function(el){
			el.addEventListener('click', _switchToTab);
		});
	}
	
	function _switchToTab(e){
		let tab = e.currentTarget;
		document.querySelector('.tab_title.active').classList.remove('active');
		tab.classList.add('active');

		document.querySelector('.tab_content.active').classList.remove('active');
		document.getElementById(tab.dataset.tab).classList.add('active');
	}

	my.init = function(){
		if(tabs.length){
			if(document.getElementById('tab_title_container')) {
				document.getElementsByClassName('tab_title')[0].classList.add('active');
				document.getElementsByClassName('tab_content')[0].classList.add('active');
			}
			_assignListeners();
		}
	};

	return my;
})();

export const tabButtons = () => tabSystem.init();